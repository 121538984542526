<template>
  <div style="position: relative; font-size: 10px; font-size: calc(10 * 100vw / 1920); font-weight: bold">
    <div v-if="is_mobile" style="text-align: center; font-size: 9em; color:#fff; position: absolute; width: 100%; top:35%">
      <div v-html="$t('title_mobile')" />
<!--      <div class="btn" style="width: auto; display: inline-block" @click="$emit('start')">{{ $t('PIEDALĪTIES') }}</div>-->
<!--      <a :href="'./winners_'+this.$i18n.locale+'.pdf'" target="_blank" class="btn" style="width: auto; display: inline-block; text-transform: uppercase">{{ $t('Laimētāji') }}</a>-->
    </div>
    <div v-else style="position: absolute; text-align: center; font-size: 4.5em; color:#fff; top: 39%; left:19%; ">
      <div v-html="$t('title')" />
<!--      <div class="btn" style="width: auto; display: inline-block" @click="$emit('start')">{{ $t('PIEDALĪTIES') }}</div>-->
<!--      <a :href="'./winners_'+this.$i18n.locale+'.pdf'" target="_blank" class="btn" style="width: auto; display: inline-block; text-transform: uppercase">{{ $t('Laimētāji') }}</a>-->
    </div>
    <img :src="bg_url" style="width: 100%; display: block" />
  </div>
</template>

<script>


export default {
  name: "Landing",
  data() {
    return {
      is_mobile: false
    }
  },
  mounted() {
    this.is_mobile = window.innerWidth <= 800
    window.onresize = () => {
      this.is_mobile = window.innerWidth <= 800
    }
  },
  computed: {
    bg_url() {
      return this.is_mobile ? require('@/assets/mobile_landing_'+this.$i18n.locale+'.jpg') : require('@/assets/landing_'+this.$i18n.locale+'.jpg')
    }
  }
}
</script>

<style scoped>

</style>
